import clsx from 'clsx';
import locales from 'data/locales';

// remove objects from filter where local code is either HI or PA
const filterLocales = (locales) => {
  return locales.filter((locale) => !['HI', 'PA'].includes(locale.code));
};
const filteredLocales = filterLocales(locales);

const LocaleList = ({
  locale: currentLocale,
  areaId: currentAreaId,
  showAll,
  updateLocation,
}) => {
  return (
    <div className='shadow-2 dropdown-menu'>
      <div className='bg-white px-20 py-20 sm:px-0 sm:py-15 rounded-4'>
        <ul className='y-gap-5 js-results'>
          {showAll ? (
            <li
              className={clsx(
                '-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1',
                !currentLocale && 'active-link'
              )}
              key='all'
              role='button'
              onClick={() => updateLocation('all', 1)}
            >
              <div className='ml-10'>
                <div className='text-15 lh-12 fw-500 js-search-option-target'>
                  Show All
                </div>
              </div>
            </li>
          ) : null}

          {filteredLocales.map((locale) => (
            <li
              className={clsx(
                '-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option mb-1',
                currentLocale + currentAreaId ===
                  locale.code.toLowerCase() + locale.area_id && 'active-link'
              )}
              key={locale.id}
              role='button'
              onClick={() =>
                updateLocation(locale.code.toLowerCase(), locale.area_id)
              }
            >
              <div className='d-flex'>
                <div className='icon-location-2 text-light-1 text-20 pt-4' />
                <div className='ml-10'>
                  <div className='text-15 lh-12 fw-500 js-search-option-target'>
                    {locale.name}
                  </div>
                  <div className='text-14 lh-12 text-light-1 mt-5'>
                    {locale.address}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LocaleList;
